var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("nav-content", { attrs: { events: _vm.eventBus } }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { mode: "in-out", css: false },
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            "after-enter": _vm.afterEnter,
            "before-leave": _vm.beforeLeave,
            leave: _vm.leave
          }
        },
        [
          _c("router-view", {
            ref: "main",
            staticClass: "main",
            attrs: {
              images: _vm.images,
              assets: _vm.assets,
              events: _vm.eventBus
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("footer-content")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "work-template",
    {
      class: "" + _vm.className,
      attrs: { project: _vm.project, events: _vm.events }
    },
    [
      _c("template", { slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "slides" },
        _vm._l(_vm.carousel_images, function(image, index) {
          return _c("div", { key: index, staticClass: "carousel-cell" }, [
            _c("img", { attrs: { src: image, alt: "" } })
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("template", { slot: "main-content" }, [
        _c("p", [
          _vm._v(
            "\n      A fun personal project I developed as a homepage concept using html, css, and js. The shapes animate using principles of projection and parallax in response to mouse movements.\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("View the demo on GitHub:")]),
        _vm._v(" "),
        _c("div", [
          _c(
            "a",
            {
              staticClass: "link-btn",
              attrs: {
                target: "_blank",
                href: "https://kyleevan.github.io/Moving-Shapes/"
              }
            },
            [_vm._v("Moving Shapes Demo")]
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "content inner-content", attrs: { id: "main-navigation" } },
    [
      _c(
        "a",
        {
          attrs: { href: "Home" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.handleClick($event)
            }
          }
        },
        [_c("nav-title", { attrs: { events: _vm.events } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "nav-wrapper" }, [
        _c(
          "ul",
          {
            ref: "navItems",
            staticClass: "nav-items",
            class: { expanded: _vm.expanded }
          },
          [
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "Home" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleClick($event)
                    }
                  }
                },
                [_vm._v("\n          projects\n        ")]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "About" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleClick($event)
                    }
                  }
                },
                [_vm._v("\n          about\n        ")]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "Contact" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleClick($event)
                    }
                  }
                },
                [_vm._v("\n          contact\n        ")]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "nav-menu",
          attrs: { role: "button", tabindex: "2", href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.handleMenuClick($event)
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: _vm.icon } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
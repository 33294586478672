var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inner-content", attrs: { id: "carouselMargin" } },
    [
      _c("div", { ref: "carouselContainer", attrs: { id: "carousel" } }, [
        _c(
          "div",
          { ref: "carousel", attrs: { id: "fixed-container" } },
          [_vm._t("default")],
          2
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            style: { backgroundColor: _vm.progressBar.background },
            attrs: {
              id: "progressBar",
              width: _vm.progressBar.width,
              height: _vm.progressBar.height,
              viewBox: _vm.viewBox
            }
          },
          [
            _c("polygon", {
              ref: "progressBar",
              staticClass: "progress",
              attrs: { fill: _vm.progressColor, points: _vm.points }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.oneSlide,
                expression: "!oneSlide"
              }
            ],
            staticClass: "prev",
            attrs: { role: "button", href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.nextPrevSlide($event)
              }
            }
          },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fas", "chevron-left"] }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.oneSlide,
                expression: "!oneSlide"
              }
            ],
            staticClass: "next",
            attrs: { role: "button", href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.nextPrevSlide($event)
              }
            }
          },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fas", "chevron-right"] }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
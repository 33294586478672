var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "about container" }, [
      _c(
        "div",
        { staticClass: "content inner-content" },
        [
          _c(
            "display-content",
            {
              staticClass: "display-container",
              attrs: { events: _vm.events, config: _vm.animationConfig }
            },
            [
              _c("h1", { staticClass: "header" }, [_vm._v("About")]),
              _vm._v(" "),
              _c("div", { staticClass: "me" }, [
                _c("img", {
                  attrs: {
                    src: _vm.portrait_image,
                    alt: "picture of me with my diploma"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bio" }, [
                _c("p", [
                  _vm._v(
                    "\n              Currently based in Rochester, New York I'm an aspiring creative developer. Graduated from RIT with a bachelors of science in  New Media & Interactive Development. I enjoy the challenge of developing beautiful front end solutions for the web, turning designs into interactions. I'm interested in learning more about 3D computer graphics, bleeding edge web technologies, UX and interaction design for media.\n            "
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
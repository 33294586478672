var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "work-template",
    {
      class: "" + _vm.className,
      attrs: { project: _vm.project, events: _vm.events }
    },
    [
      _c("template", { slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "slides" },
        _vm._l(_vm.carousel_images, function(image, index) {
          return _c("div", { key: index, staticClass: "carousel-cell" }, [
            _c("img", { attrs: { src: image, alt: "" } })
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("template", { slot: "main-content" }, [
        _c("p", [
          _vm._v(
            "\n      The member redesign project was a complete UI and UX overhaul of Excellus BCBS and Univera Healthcare member sections. This massive project required a manual transition to a responsive theme introducing new theme and coding standards to create a fresh modern experience for our members.\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Before the redesign, the websites were static with a fixed layout and a completely separate mobile website. This project solved many of the issues that plagued productivity by facilitating the creation and maintenance of content because it introduced one central code base. It also greatly improved the website's usability, streamlined the member experience, and added new accessibility features.\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      A majority of the project I worked on migrating ui components and content, adding responsive functionality and ensuring compliance according to section 508 WAI-ARIA specifications. Bootstrap 3 was the main framework we used in our responsive theme to handle layouts for different viewports. I also developed internal and external web applications trhoughout the duration of the redesign. \n    "
          )
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Careers")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      I developed the entire Careers sections of these websites based on designs provided by a UX designer on my team. The web application I am most proud of was a calendar application for upcoming recruitment events for the Careers section of both Excellus and Univera websites. \n    "
          )
        ]),
        _vm._v(" "),
        _c("video", { attrs: { autoplay: "", loop: "" } }, [
          _c("source", { attrs: { src: _vm.video, type: "video/mp4" } }),
          _vm._v("\n      Your browser does not support the video tag.\n    ")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("View the production applications:")]),
        _vm._v(" "),
        _c("div", [
          _c(
            "a",
            {
              staticClass: "link-btn",
              attrs: { target: "_blank", href: _vm.links.excellusCalendar }
            },
            [_vm._v("Excellus Calendar Application")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "link-btn",
              attrs: { target: "_blank", href: _vm.links.univeraCalendar }
            },
            [_vm._v("Univera Calendar Application")]
          )
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "links-list" }, [
          _c("li", [
            _c("h2", [_vm._v("Production")]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n          View the production sites:\n        ")
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link-btn",
                attrs: { target: "_blank", href: _vm.links.excellusHome }
              },
              [_vm._v("Excellus BCBS Home")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link-btn",
                attrs: { target: "_blank", href: _vm.links.univeraHome }
              },
              [_vm._v("Univera Healthcare Home")]
            )
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "display-content",
        {
          ref: "home_title",
          attrs: {
            events: _vm.events,
            config: _vm.animationConfig,
            id: "home-title"
          }
        },
        [
          _c("ul", [
            _c("li", { staticClass: "header" }, [_vm._v("Kyle")]),
            _vm._v(" "),
            _c("li", { staticClass: "header" }, [_vm._v("Peterson")]),
            _vm._v(" "),
            _c("li"),
            _vm._v(" "),
            _c("li", [_vm._v("New Media")]),
            _vm._v(" "),
            _c("li", [_vm._v("& Interactive")]),
            _vm._v(" "),
            _c("li", [_vm._v("Developer")])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
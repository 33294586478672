var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "work-template",
    {
      class: "" + _vm.className,
      attrs: { project: _vm.project, events: _vm.events }
    },
    [
      _c("template", { slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "slides" },
        _vm._l(_vm.project_images, function(image, index) {
          return _c("div", { key: index, staticClass: "carousel-cell" }, [
            _c("img", { attrs: { src: image, alt: "" } })
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("template", { slot: "main-content" }, [
        _c("p", [
          _vm._v("\n      The first game I ever developed "),
          _c("i", [_vm._v("Cycles")]),
          _vm._v(
            " is a simple 2D desktop web game designed to help users practice and learn basic multiplication skills. It has an 8-bit theme and was inspired by old computer games. I worked on this as a personal project for my Rich Media in Web Applications class. It uses no libraries, has no dependencies and was created with plain Javascript, Canvas API, HTML, and CSS.\n    "
          )
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "split" }, [
          _c("div", [
            _c("h2", [_vm._v("Gameplay")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          The player must survive longer than the computer driving a vehicle(cycle) without crashing. Colored lines are drawn in the wake of each cycle and those lines create new barriers for the player and the computer. Cycles accelerate on their own, it only requires the player to change the direction (up, down, left, right) by solving an arithmatic problem. Math operations in the center of the screen are positioned respective to the direction the cycle will be turned.\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gif" }, [
            _c("img", {
              attrs: { src: _vm.gameplay_gif, alt: "Cycles gameplay preview" }
            })
          ])
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "links-list" }, [
          _c("li", [
            _c("h2", [_vm._v("GitHub Project")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            Contains more detailed instructions on how to play the game.\n          "
              )
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link-btn",
                attrs: { target: "_blank", href: _vm.links.github }
              },
              [_vm._v("Cycles")]
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "show-desktop" }, [
            _c("h2", [_vm._v("Play the Game")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            This game was developed for desktop and requires a keyboard.\n          "
              )
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link-btn",
                attrs: { target: "_blank", href: _vm.links.game }
              },
              [_vm._v("Play Cycles")]
            )
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "nav-title" } }, [
    _c(
      "h1",
      { ref: "name" },
      [
        _c("charm-word", { attrs: { config: _vm.nameConfig } }, [
          _vm._v("Kyle")
        ]),
        _vm._v(" "),
        _c("charm-word", { attrs: { config: _vm.nameConfig } }, [
          _vm._v("Peterson")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { ref: "title" }, [
      _c("span", { staticClass: "border" }),
      _vm._v(" "),
      _c("h2", [_vm._v("\n        Front End Developer\n      ")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "work-template",
    {
      class: "" + _vm.className,
      attrs: { project: _vm.project, events: _vm.events }
    },
    [
      _c("template", { slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "slides" },
        _vm._l(_vm.project_images, function(image, index) {
          return _c("div", { key: index, staticClass: "carousel-cell" }, [
            _c("img", { attrs: { src: image, alt: "" } })
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("template", { slot: "main-content" }, [
        _c("p", [
          _vm._v(
            "\n      A final project for my Elements of Graphic Design in Media class. The subject was ours to choose and the main objective was to incorporate some principles of 2D graphic design learned during the semester. I chose to create a poster advertisement for a comic book store. I used Adobe Illustrator for the vector graphics, characters and text. Characters were created from reference images of old batman comic book covers. I post processed the result in Photoshop adjustmenting colors and layering a texture filter to create the distressed old finishing. At the end, the high resolution composition was printed on 11x18 foamcore.\n    "
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }